import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "show", "hidden"]
  static values = { on: { type: String, default: "" } }

  connect() {
    this.toggle()
    this.handleSubmit = this.handleSubmit.bind(this)
    document.addEventListener("turbo:morph", this.handleSubmit);
  }

  disconnect() {
    document.removeEventListener("turbo:morph", this.handleSubmit);
  }

  toggle() {
    if (this.inputTarget.checked || this.inputTarget.value === this.onValue) {
      this._reveal()
    } else {
      this._hide()
    }
  }

  handleSubmit() {
    this.toggle()
  }

  _reveal() {
    this.showTarget.classList.remove("hidden")
    this.hiddenTarget.classList.add("hidden")
  }

  _hide() {
    this.showTarget.classList.add("hidden")
    this.hiddenTarget.classList.remove("hidden")
  }
}
