import { Controller as BaseController } from "@hotwired/stimulus"
import AirDatepicker from "air-datepicker"
import "air-datepicker/air-datepicker.css"
import localeEn from 'air-datepicker/locale/en';

export class Controller extends BaseController {
  static targets = ["dateRangeOutput"]
  static values = { dateRange: Array }

  connect() {
    this.datePicker = this._initializeDatePicker()
    this._disableTextInput()
    this.dateRangeValue = this._parseInput()
  }

  apply() {
    if (this.datePicker.selectedDates.length > 1) {
      this.dateRangeValue = this.datePicker.selectedDates

      let timestampDates = this.datePicker.selectedDates.map(
        (date) => Date.parse(date)
      )

      this.dateRangeValue = timestampDates
    }
  }

  toggle() {
    if (this.datePicker.visible) {
      this.datePicker.hide()
    } else {
      this.datePicker.show()
    }
  }

  dateRangeValueChanged(value) {
    if (value.length === 0) {
      if (this.datePicker) {
        this.datePicker.clear()
      }
      return
    }

    if (value.includes(null)) {
      return
    }

    const fromDate = new Date(value[0])
    const toDate = new Date(value[1])
    const formatted = `${this._parseDate(fromDate)} - ${this._parseDate(toDate)}`

    this.dateRangeOutputTarget.value = formatted
    this.datePicker.selectDate([fromDate, toDate])
  }

  clear() {
    this.dateRangeValue = []
  }

  _parseInput() {
    const value = this.dateRangeOutputTarget.value
    return value.split(" - ").map(
      (date) => Date.parse(date)
    )
  }

  _disableTextInput() {
    this.dateRangeOutputTarget.readOnly = true
  }

  _parseDate(date) {
    const [month, day, year] = [
      date.getMonth() + 1,
      date.getDate(),
      date.getFullYear(),
    ]

    return `${month}/${day}/${year}`
  }

  _initializeDatePicker() {
    const customResetButton = {
      content: "Reset",
      attrs: { type: "button" },
      onClick: () => {
        this.clear()
      }
    }

    const customApplyButton = {
      content: "Apply",
      attrs: { type: "button" },
      onClick: () => {
        this.apply()
        this.datePicker.hide()
      }
    }

    const options = {
      locale: localeEn,
      range: true,
      showEvent: false,
      multipleDatesSeparator: " - ",
      position: "bottom right",
      buttons: [customApplyButton, customResetButton]
    }

    return new AirDatepicker(
      "#datepicker-calendar",
      options
    )
  }
}
